<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 설비유형 -->
          <c-equip-class
            :editable="editable"
            type="search"
            label="LBL0000633"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 설비 -->
          <c-equip
            label="LBLEQUIP"
            type="search"
            placeholder="전체"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 중요도 -->
          <c-select
            :editable="editable"
            codeGroupCd="MDM_EQUIP_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="grade"
            label="LBL0003622"
            v-model="searchParam.grade"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관리부서 -->
          <c-dept label="LBL0003623" type="search" name="managementDepts" v-model="searchParam.managementDepts" />
        </div>
      </template>
    </c-search-box>
    <!-- 설비 중요도 및 법정점검주기 -->
    <c-table
      ref="table"
      title="LBL0003624"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="equipmentTypeCd"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- <c-btn label="LBLREG" icon="add_circle" /> -->
          <!-- 출력 -->
          <c-btn label="LBL0003625" icon="print" @btnClicked="btnPrint" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'important-check-cycle',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
        ],
        columns: [
          {
            // 설비유형
            label: 'LBL0000633',
            align: 'center',
            child: [
              {
                name: 'upEquipmentTypeName',
                field: 'upEquipmentTypeName',
                // 대분류
                label: 'LBL0003626',
                style: 'width:200px', 
                align: 'center',
                sortable: false
              },
              {
                name: 'equipmentTypeName',
                field: 'equipmentTypeName',
                // 중분류
                label: 'LBL0003627',
                style: 'width:170px', 
                align: 'center',
                sortable: false,
              },
            ]
          },
          {
            name: 'equipmentCdName',
            field: 'equipmentCdName',
            // 해당설비명(관리번호)
            label: 'LBL0003628',
            style: 'width:250px',
            align: 'left',
            sortable: false,
            fix: true,
            type: "link",
          },
          {
            name: 'grade',
            field: 'grade',
            // 중요도
            label: 'LBL0003629',
            style: 'width:80px',
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'MEGC000001', stepperMstNm: 'A등급', colorClass: 'blue' },
              { stepperMstCd: 'MEGC000005', stepperMstNm: 'B등급', colorClass: 'green' },
              { stepperMstCd: 'MEGC000010', stepperMstNm: 'C등급', colorClass: 'orange' },
              { stepperMstCd: 'MEGC000015', stepperMstNm: 'D등급', colorClass: 'gray' },
            ],
            align: 'center',
            sortable: false,
          },
          {
            name: 'checkCycle',
            field: 'checkCycle',
            // 법정점검주기
            label: 'LBL0003630',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'dailyInspection',
                field: 'dailyInspection',
                // 일상
                label: 'LBL0003631',
                align: 'center',
                style: 'width:45px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'twoWeeks',
                field: 'twoWeeks',
                // 2주
                label: 'LBL0003632',
                align: 'center',
                style: 'width:45px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'oneMonth',
                // 1개월
                field: 'oneMonth',
                label: 'LBL0003633',
                style: 'width:45px',
                align: 'center',
                sortable: false,
                // type: "link",
              },
              {
                name: 'twoMonth',
                field: 'twoMonth',
                // 2개월
                label: 'LBL0003634',
                align: 'center',
                style: 'width:45px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'sixMonth',
                field: 'sixMonth',
                // 6개월
                label: 'LBL0003635',
                align: 'center',
                style: 'width:45px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'annualRemuneration',
                field: 'annualRemuneration',
                // 년차보수
                label: 'LBL0003636',
                align: 'center',
                sortable: false,
                style: 'width:45px',
                // type: "link",
              },
            ]
          },
          {
            // 정비구분
            label: 'LBL0003637',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'operFlag',
                field: 'operFlag',
                // 운전
                label: 'LBL0003638',
                align: 'center',
                sortable: false,
                // type: "link",
              },
              {
                name: 'pmFlag',
                field: 'pmFlag',
                // 예방
                label: 'LBL0003639',
                align: 'center',
                sortable: false,
                // type: "link",
              },
              {
                name: 'predictiveFlag',
                field: 'predictiveFlag',
                // 예측
                label: 'LBL0003640',
                align: 'center',
                sortable: false,
                // type: "link",
              },
              {
                name: 'cmFlag',
                field: 'cmFlag',
                // 고장
                label: 'LBL0003641',
                align: 'center',
                sortable: false,
                // type: "link",
              },
            ]
          },
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            style: 'width:80px',
            align: 'center',
            sortable: false,
            // rowspan: 5,
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            // 관리부서
            label: 'LBL0003623',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            style: 'width:200p',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        grade: null,
        inspectionCycleCd: null,
        equipmentCheckTypeCd: null,
        managementDepts: '',
        equipmentTypeCd: '',
        equipmentCd: '',
      },
      editable: true,
      listUrl: '',
      printUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.sop.min.equipment.checkCycle.list.url;
      this.printUrl = selectConfig.sop.min.equipment.checkCycle.print.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },
      );
    },
    linkClick(row, col) {
      this.equipmentPopup(row, col);
    },
    equipmentPopup(result, col) {
      if (!col.value) return;
      let param = null;
      // 설비마스터 상세
      if (col.name === 'equipmentCdName') {
        param = { 
          equipmentCd: result.equipmentCd,
        }
        this.popupOptions.title = 'LBL0003642'; // 설비마스터 상세
        this.popupOptions.target = () =>
        import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.param = param;
        this.popupOptions.isFull = true;
        this.popupOptions.closeCallback = this.closePopup;
      } 
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    btnPrint() {
      let thisVue = this;
      this.$http.url = this.printUrl
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request(
        _result => {
          let fileOrgNm = '설비중요도 및 점검주기' + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
